@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  scroll-behavior: smooth;
  list-style: none;
  color: #000;
  margin: 0;
}
body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  transition: all 0.2s ease;
}

a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  a {
    text-align: center; /* Ensure text is centered */
    flex-direction: column; /* Column layout */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    width: 100%; /* Make the link take full width for better tap targets */
  }
}

.font-sans {
  font-family: "DM Sans", sans-serif;
}

.font-poppin {
  font-family: "Poppins", sans-serif;
}

.text-bold {
  font-weight: 500;
}

.text-white {
  color: #000000;
  font-family: Roboto;
  font-size: large;
  font-weight: 100;
}

.text-center {
  text-align: center;
}

.bg-dark {
  background-color: #fff;
}

.bg-dark-light {
  background-color: #fff;
}

.d-flex {
  display: flex;
  flex-direction: row;
}

.margin-0 {
  margin: 0;
}

/* Main container that holds the entire content */
.div2 {
  display: flex; /* Flex layout to align content horizontally */
  align-items: center; /* Vertically center the content */
  justify-content: space-between; /* Distribute space evenly between image and text */
  /* Add some padding */
  width: 100%; /* Full width of container */
  max-width: 1200px; /* Limit the width of the content */
  margin: 0 auto; /* Center the container on the page */
}

/* Image styling */
.div2 img {
  width: 400px; /* Set a larger width for the image */
  height: auto; /* Maintain aspect ratio */
  margin-right: 50px; /* Space between image and text */
}

/* Paragraph styling */
.div2 p {
  font-size: 25px; /* Font size for paragraph */
  line-height: 1.5; /* Line height for readability */
  color: #050505; /* Text color */
  flex: 1; /* Allow text to take the remaining space */
  max-width: 700px; /* Set a maximum width for text block */
  margin: 0; /* Remove default margin */
  text-align: justify;
  font-family: "Poppins", sans-serif; /* Justify text for clean alignment */
}
.div2 p span {
  color: #be2048;
  text-transform: capitalize;
  font-weight: 700;
}

/* Responsive Design */
@media (max-width: 768px) {
  .div2 {
    flex-direction: column; /* Stack image and text vertically on small screens */
    align-items: center; /* Center the items */
  }

  .div2 img {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Add space below the image */
    width: 80%; /* Make image responsive */
  }

  .div2 p {
    max-width: 80%; /* Ensure the text takes full width on small screens */
    text-align: center;
    font-family: "Poppins", sans-serif; /* Center text on small screens */
  }
}
