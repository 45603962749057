.nav-bar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  position: fixed; /* Changed from sticky to fixed */
  top: 0; /* Ensure it stays at the top */
  left: 0;
  height: 60px;
  width: 100%; /* To ensure it spans the full width of the page */
  /* padding: 12px 20px; */
  background-color: transparent; /* Make the background transparent */
  backdrop-filter: blur(10px);
  z-index: 999; /* Ensures it's above other content */
  box-shadow: var(--navbar-shadow) 0px 5px 15px 0px;
}

nav {
  color: #f7444e; /* Keep the nav transparent */
  z-index: 999;
  box-shadow: var(--navbar-shadow) 0px 5px 15px 0px;
}

.logo img {
  width: 250px;
  margin-top: 2opx;
}
.menu {
  display: flex;
  gap: 2rem;
}
.menu li {
  color: #000;
}
.menu li a {
  font-family: "Poppins";
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  transition: 0.15s ease-in-out;
  position: relative;
  font-size: large;
  /* margin-right: 2rem; */
}

.menu li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: black;
  transition: 0.15s ease-in-out;
}
.menu li a:hover:after {
  width: 100%;
}
.open-menu,
.close-menu {
  position: absolute;
  cursor: pointer;
  font-size: 1.5rem;
  display: none;
}
.open-menu {
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.close-menu {
  top: 20px;
  right: 20px;
}

#check {
  display: none;
}

.btn-design {
  background: rgb(190, 32, 72);
  color: #f7444e;
  border-radius: 99px;
  box-sizing: border-box;
  cursor: pointer;

  line-height: 24px;
  opacity: 1;
  color: #ffffff;
  outline: 0 solid transparent;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

/* Theme Change Toggle */

/* Theme Change Toggle */

.wrapper {
  margin: auto;
}

#hide_checkbox {
  opacity: 0;
  height: 0;
  width: 0;
}

.toggle {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 60px;
  height: 30px;
  background: #211042;
  border-radius: 50px;
  transition: background 500ms;
  overflow: hidden;
}

.toggle-button {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: #faeaf1;
  border-radius: 50%;
  box-shadow: 0 0 35px 4px rgba(255, 255, 255);
  transition: transform 500ms ease-out, background 500ms;
  /* Ensure it stays within the bounds */
  transform: translateX(0);
}

#hide_checkbox:checked + .toggle {
  background: #24d7f7;
}

#hide_checkbox:checked + .toggle .toggle-button {
  /* Shift the toggle button to the right when checked */
  transform: translateX(
    30px
  ); /* Adjust this value based on width of the toggle */
  background: #f7ffff;
}

.crater {
  position: absolute;
  background: #faeaf1;
  border-radius: 50%;
  transition: 500ms;
}

/* Define crater positions */
.crater-1 {
  width: 26px;
  height: 26px;
  left: 4px;
  bottom: 3px;
}

.crater-2 {
  width: 7px;
  height: 7px;
  top: -2px;
  left: 12px;
}

.crater-3 {
  width: 6px;
  height: 6px;
  top: 5px;
  right: -2px;
}

.crater-4 {
  width: 5px;
  height: 5px;
  top: 12px;
  left: 15px;
}

.crater-5 {
  width: 3px;
  height: 3px;
  top: 7px;
  left: 7px;
}

.crater-6 {
  width: 10px;
  height: 10px;
  top: 15px;
  left: 4px;
}

.crater-7 {
  width: 12px;
  height: 12px;
  bottom: -4px;
  left: 5px;
}

.star {
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  box-shadow: 1px 0 2px 2px rgba(255, 255, 255);
}

.star-1 {
  width: 3px;
  height: 3px;
  right: 19px;
  bottom: 23px;
}

.star-2 {
  width: 1px;
  height: 1px;
  right: 30px;
  top: 29px;
}

.star-3 {
  width: 1px;
  height: 1px;
  right: 5px;
  bottom: 15px;
}

.star-4 {
  width: 1px;
  height: 1px;
  right: 35px;
  bottom: 33px;
}

.star-5 {
  width: 4px;
  height: 4px;
  right: 10px;
  bottom: 35px;
}

.star-6,
.star-7,
.star-8 {
  width: 10px;
  height: 2px;
  border-radius: 2px;
  transform: rotate(-45deg);
  box-shadow: 5px 0px 4px 1px #fff;
  animation-name: travel;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

.star-6 {
  right: 22px;
  bottom: 25px;
  animation-delay: -2s;
}

.star-7 {
  right: 11px;
  bottom: 18px;
}

.star-8 {
  right: 33px;
  top: 10px;
  animation-delay: -4s;
}

@keyframes travel {
  0% {
    transform: rotate(-45deg) translateX(70px);
  }

  50% {
    transform: rotate(-45deg) translateX(-20px);
    box-shadow: 5px 0px 6px 1px #fff;
  }

  100% {
    transform: rotate(-45deg) translateX(-30px);
    width: 2px;
    height: 2px;
    opacity: 0;
    box-shadow: none;
  }
}

#hide_checkbox:checked + .toggle {
  background: #24d7f7;
}

#hide_checkbox:checked + .toggle .toggle-button {
  background: #f7ffff;
  transform: translateX(32px);
  box-shadow: 0 0 35px 5px rgba(255, 255, 255);
}

#hide_checkbox:checked + .toggle .toggle-button .crater {
  transform: rotate(-45deg) translateX(70px);
}

#hide_checkbox:checked + .toggle .star {
  animation: move 2s infinite;
  transform: none;
  box-shadow: none;
}

#hide_checkbox:checked + .toggle .star-1 {
  width: 21px;
  height: 6px;
  border-radius: 10px;
  background: #fff;
  left: 7px;
  top: 22px;
  box-shadow: none;
}

#hide_checkbox:checked + .toggle .star-2 {
  width: 9px;
  height: 9px;
  background: #fff;
  left: 10px;
  top: 19px;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}

#hide_checkbox:checked + .toggle .star-3 {
  width: 11px;
  height: 11px;
  background: #fff;
  left: 16px;
  top: 17px;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}

#hide_checkbox:checked + .toggle .star-4 {
  width: 9px;
  height: 9px;
  background: #fff;
  left: 23px;
  top: 19px;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}

#hide_checkbox:checked + .toggle .star-5 {
  width: 15px;
  height: 6px;
  border-radius: 15px;
  background: #fff;
  left: 13px;
  bottom: 23px;
  box-shadow: none;
}

#hide_checkbox:checked + .toggle .star-6 {
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 50%;
  left: 16px;
  bottom: 23px;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}

#hide_checkbox:checked + .toggle .star-7 {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  left: 19px;
  bottom: 23px;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}

#hide_checkbox:checked + .toggle .star-8 {
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 50%;
  left: 23px;
  top: 0px;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}

@keyframes move {
  0% {
    transform: none;
  }

  25% {
    transform: translateX(2px);
  }

  100% {
    transform: translateX(-2px);
  }
}

.wrapper p {
  text-align: center;
  letter-spacing: 15px;
  background: #0470dd;
  color: #fff;
}

p.morning {
  background: #e67e22;
}

/* Theme Change Toogle Ended */

@media only screen and (min-width: 900px) {
  /* For Desktop */

  .open-menu span{
    display: none;
  }

  .btn-design {
    font-size: 16px;
    padding: 8px 28px;
  }

  .nav-bar {
    padding: 1% 0%;
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  /* For Mobile */
  .menu {
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 100vh;
    position: fixed;
    top: 60px;
    right: -100%;
    z-index: 100;
    background-color: var(--blog);
    transition: all 0.2s ease-in-out;
  }
  .menu li {
    margin-top: 40px;
  }
  .menu li a {
    padding: 10px 0px;
  }

  .btn-design {
    font-size: 16px;
    padding: 8px 28px;
  }

  .wrapper {
    margin: 0px 25px 0px 0px;
  }

  .open-menu,
  .close-menu {
    display: block;
  }

  #check:checked ~ .menu {
    right: 0;
  }
}

/* General Styles for Larger Devices (desktops, large screens) */

/* Small Laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .nav {
    display: flex;
    justify-content: space-between;
  }

  .nav-item {
    font-size: 16px;
    margin: 0 15px;
  }
}

/* Tablets (portrait and landscape) */
@media (min-width: 481px) and (max-width: 768px) {
  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-item {
    font-size: 14px;
    margin: 10px 0;
  }
}

/* Smartphones and Small Mobile Devices */
@media (max-width: 480px) {
  .nav {
    display: block;
    text-align: center;
  }

  .nav-item {
    font-size: 12px;
    margin: 10px 0;
  }
}

/* Smartwatches */
@media (max-width: 200px) {
  .nav {
    display: block;
    text-align: center;
  }

  .nav-item {
    font-size: 10px;
    margin: 5px 0;
  }
}
/* General Menu Styles */
@media (max-width: 768px) {
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: var(--blog);
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* Menu open state */
#check:checked ~ .menu {
  height: auto;
  padding: 20px 0;
  display: block;
}

.menu li {
  text-align: center;
  margin: 10px 0;
}

/* Menu links styling */
.menu li a {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  text-decoration: none;
  display: block;
  transition: 0.3s;
}

.menu li a:hover {
  color: #f7444e; /* Hover color */
}

/* Hamburger Menu Icon (3 lines) */
.menu-line {
  display: block;
  width: 30px;
  height: 4px;
  background-color: #dc1c1c;
  margin: 5px 0;
  transition: 0.4s;
}

/* Open/Close Menu Buttons */
.open-menu,
.close-menu {
  cursor: pointer;
  display: none;
}

.open-menu {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.close-menu {
  position: absolute;
  top: 10px;
  right: 20px;
}

/* Show the buttons on mobile devices */

.open-menu,
.close-menu {
  display: block;
}

#check:checked ~ .menu {
  height: auto;
}
