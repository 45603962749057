.services-container {
  background-color: #e5d7d7;
  color: white;
  padding: 50px;
  font-family: Arial, sans-serif;
 
}

.services-header {
  text-align: center;
  margin-bottom: 40px;
}

.services-header h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.services-header .highlight {
  color: #be2048;
}

.services-header p {
  font-size: 1.2rem;
  color: #a0aec0;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 4rem;
}

.service-card {
  background-color: #d48c8f;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease; /* Transition added */
}

.service-card:hover {
  transform: scale(1.05); /* Zoom effect on hover */
  background-color: #d68196; /* Change background color on hover */
}

.service-card .icon {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #ff477e;
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service-card ul {
  list-style: none;
  padding: 0;
}

.service-card ul li {
  margin-bottom: 8px;
  color: #060606;
}

.service-card p {
  color: #060606;
}


/* General Styles for Popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #d8d8c2;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}
.popup-content h2{
  color: #157d03;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.popup-close-button {
  background-color:#be2048;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

/* Media Queries for Responsiveness */

/* Mobile Devices (Up to 600px) */
@media (max-width: 600px) {
  .popup-content {
    width: 90%; /* Full width on small screens */
  }
  
  .services-grid {
    display: block;
  }

  .service-card {
    margin-bottom: 20px;
  }
}

/* Tablet Devices (Up to 768px) */
@media (max-width: 768px) {
  .popup-content {
    width: 80%;
  }

  .services-grid {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .service-card {
    font-size: 16px;
  }
}

/* Laptops and Desktops (Up to 1024px) */
@media (max-width: 1024px) {
  .popup-content {
    width: 70%;
  }

  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Large Screens (Above 1024px) */
@media (min-width: 1024px) {
  .popup-content {
    width: 400px; /* Default size for larger screens */
  }

  .services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  .service-card {
    padding: 20px;
    transition: transform 0.3s ease;
  }

  .service-card:hover {
    transform: scale(1.05);
  }
}






@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
}
