.about-us {
    background-color: #fff;
    color: #e5d7d7;
    border-radius: 1.5rem;
   
  }
  
  .instructor-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .instructor-title p {
    font-size: 2.5rem;
    margin: 0;
    text-align: left;
  }
  
  .instructor-title p span {
    color: #be2048;
    font-size: larger;
    margin-left: 2rem;
  }
  
  .instructor-content {
    padding: 0rem 0;
  }
  
  
  
  .line-long {
    width: 100%;
    border-bottom: 1px solid var(--acsent-color);
  }
  
  .name-div {
    margin-top: 2rem;
  }
  
  .name {
    font-size: 1.875rem;
  
    line-height: 2.25rem;
    margin: 0;
    padding: 0;
  }
  
  .designation {
    color: var(--acsent-color);
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0;
    font-weight: 400;
    padding: 0;
  }
  
  .description-div {
    border: 1px dashed var(--course-border);
    padding: 20px;
    border-radius: 15px;
  }
  
  .description {
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 2rem;
    margin: 0;
    text-align: justify;
    padding: 0;
  }
  
  .description span {
      color: var(--blog);
  }

  
    #instructor {
      margin-top: 5rem;
      
    }

    /*my*/
    .about-container {
      padding: 40px;
      font-family: Arial, sans-serif;
      background-color: #fff;
     
    }
    
    .intro-section h1 {
      font-size: 3rem;
      margin-bottom: 20px;
    }
    
    .intro-section p {
      font-size: 1.2em;
      line-height: 1.6;
    }
    
    .mission-vision-section {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
    }
    
    .mission, .vision {
      width: 45%;
      border: 2px solid #be2048; /* Added border */
      padding: 15px; /* Optional: Add padding for better spacing inside the border */
      border-radius: 10px; /* Optional: Rounded corners */
    }
    
    .mission h2, .vision h2 {
      font-size: 1.8em;
      margin-bottom: 15px;
      color: #be2048;
    }
    
    .mission p, .vision p {
      font-size: 1.1em;
      line-height: 1.6;
    }
    
    .values-section {
      margin-top: 40px;
    }
    
    .values-section h2 {
      font-size: 1.8em;
      margin-bottom: 15px;
    }
    
    .values-section p {
      font-size: 1.1em;
      line-height: 1.6;
    }
    /*my*/
  
  
  @media only screen and (max-width: 900px) {
    /* For Phone */
    .image-div img {
      width: 13rem;
      height: 13rem;
     
    }
  
    .instructor-title p {
      font-size: 24px;
    }
  
    .about-us {
      width: 100%;
     
    }
  }
  