.testimonials {
  padding: 50px;
  background-color: #f7f7f7;
  text-align: center;
}

h2 {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: bold;
}

.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.testimonial-card {
  margin-top: 2rem;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  position: relative;
}

.client-photo {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.client-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.client-review h4 {
  margin-top: 60px;
  font-size: 18px;
  font-weight: bold;
}

.review-date {
  font-size: 12px;
  color: gray;
}

.review-sec{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 5rem;
}

.stars {
  margin-top: 10px;
}

.star-placeholder {
  display: inline-block;  /* Make sure they align in a row */
  width: 20px;           /* Width of the star space */
  height: 20px;          /* Height of the star space */
  background-color: transparent; /* or you can set it to match your design */
  margin-right: 3px;     /* Space between stars */
}
