.social-card-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .social-card {
    display: flex;
    align-items: center;
    margin: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .social-icon {
    width: 6rem; /* Set the width to 16rem */
    height: 6rem; /* Set the height to 16rem */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .social-icon svg {
    width: 100%;
    height: 100%;
    color: #be2048; /* Updated icon color */
  }
  
  .social-text {
    font-size: 18px;
    color: #be2048;
  }
  
  .text-link {
    text-decoration: none;
    color: #d51212;
  }
  
  .text-link:hover {
    text-decoration: underline;
  }
  