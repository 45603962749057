/* General styles for body */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  padding-top: -600px;
  width: 100vw;
  overflow-x: hidden;
  margin-top: 2.5rem;
}

/* Contact Section */
.contact-section {
  position: relative;
  text-align: center;
  color: white;
}

.contact-bg {
  position: relative;
  height: 60vh;
}

.image {
  top: 2.9rem;
  background-image: url('../../Adv_sec/contact_bg.jpg');
  height: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black layer */
  z-index: 1;
}

.contact-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Ensures the text is above the black layer */
  color: white;
}

.contact-title h1 {
  font-size: 3em;
  margin: 0;
  color: #be2048;
}

.contact-title p {
  font-size: 1.5em;
  margin: 0;
  color: #fff;
}

/* Add a container class to center the form */
.contact-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full viewport height */
  background-color: #f8f8f8; /* Optional background color */
}

.contact-form {
  width: 400px;
  background-color: #fff;
  padding: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
}

.contact-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  color: #000000;
  text-align: left;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #2f2e2e;
  border-radius: 4px;
  font-size: 14px;
}

.contact-form textarea {
  height: 150px;
  resize: none;
}

.contact-form input[type="submit"] {
  width: 100%;
  padding: 12px;
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.contact-form input[type="submit"]:hover {
  background: linear-gradient(to right, #ff4b2b, #ff416c);
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* Media Queries */

/* Large Devices (Laptops, Desktops) */
@media (min-width: 1200px) {
  .contact-form {
    width: 450px;
  }

  h1 {
    font-size: 28px;
  }
}

/* Medium Devices (Tablets) */
@media (max-width: 768px) {
  .contact-form {
    width: 300px;
    padding: 30px;
  }

  h1 {
    font-size: 22px;
  }
}

/* Small Devices (Phones) */
@media (max-width: 480px) {
  .contact-form {
    width: 260px;
    padding: 20px;
  }

  h1 {
    font-size: 20px;
  }

  .contact-form input[type="submit"] {
    font-size: 14px;
  }
}

/* Contact Details */
.contact-container {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  margin: 50px auto; /* Adds margin from the top, and centers content horizontally */
  max-width: 1200px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-details,
.address-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-icon {
  font-size: 24px;
  color: #00bfa6;
}

h3 {
  margin: 0;
  font-size: 18px;
  color: #010101;
}

p {
  margin: 0;
  color: #060606;
  font-size: 16px;
}

/* Responsive Design */
/* Large Devices (Desktops) */
@media (min-width: 1024px) {
  .contact-form {
    max-width: 500px;
    padding: 30px;
  }
}

/* Tablets */
@media (max-width: 1024px) and (min-width: 768px) {
  .contact-form {
    max-width: 600px;
    padding: 20px;
  }

  .contact-title h1 {
    font-size: 2.5em;
  }

  .contact-title p {
    font-size: 1.3em;
  }
}

/* Mobile (Large) */
@media (max-width: 768px) {
  .contact-form {
    max-width: 90%;
    padding: 15px;
  }

  .contact-form input[type="submit"] {
    font-size: 0.9rem;
    padding: 10px;
  }

  .contact-title h1 {
    font-size: 2em;
  }

  .contact-title p {
    font-size: 1.2em;
  }

  .contact-bg {
    height: 40vh;
  }

  .contact-container {
    flex-direction: column;
    padding: 20px;
  }

  .contact-details,
  .address-details {
    width: 100%;
  }
}

/* Mobile (Small) */
@media (max-width: 480px) {
  .contact-form {
    max-width: 95%;
    padding: 10px;
  }

  .contact-form label {
    font-size: 0.9rem;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 8px;
    font-size: 0.85rem;
  }

  .contact-form input[type="submit"] {
    font-size: 0.85rem;
    padding: 8px;
  }

  .contact-title h1 {
    font-size: 1.5em;
  }

  .contact-title p {
    font-size: 1em;
  }

  .contact-bg {
    height: 30vh;
  }

  .contact-us-container h3 {
    font-size: 1.5rem;
  }

  .contact-container {
    padding: 20px;
  }
}
