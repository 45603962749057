:root .light {
  --primary-bg: #ffff;
  --secondary-bg: #f2eaea;
  --primary-color: #f3eded;
  --secondary-color: #fff;
  --blog: #e9b9c5;
  --blog-secondary: #e9b9c5;
  --acsent-color: rgb(217, 200, 200);
  --navbar-shadow: rgba(232, 214, 214, 0.17);
  --navbar-shadow-invert: rgba(234, 215, 215, 0.17);
}

:root .dark {
  --primary-bg: #ccd3d6;
  --secondary-bg: #d3dee3;
  --primary-color: #fff;
  --secondary-color: #c2a5a5;
  --blog: #e9b9c5;
  --blog-secondary: #e9b9c5;
  --acsent-color: rgb(189, 189, 189);
  --navbar-shadow: rgba(239, 188, 188, 0.17);
} 